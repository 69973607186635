.our-mission {
  margin-top: 24px;
  margin-bottom: 24px;
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.our-mission img {
  object-fit: contain;
}

.our-mission,
.our-mission p {
  color: var(--text-color);
}

.image-container {
  position: relative;
  overflow: hidden; /* Hide the portions of the images that are outside the container */
}

.image-container img {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100%;
  transform: translate(-50%, -50%);
  object-fit: cover;
}
