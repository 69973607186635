.home {
  margin-top: 24px;
  margin-bottom: 24px;
  flex: 1;
  width: 100%;
}

.home__search {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.home__content {
  margin-top: 24px;
  display: flex;
  gap: 24px;
  min-height: 500px;
}

.home__content__maps {
  position: relative;
  width: 50%;
  overflow: hidden;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.home__content__table {
  width: 50%;
}

.home__content__table__title {
  text-align: center;
  margin-bottom: 16px;
  color: var(--text-color);
}

.home__content__map {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 768px) {
  .home__search {
    flex-direction: column;
  }

  .home__search .btn {
    margin-top: 8px;
    width: 100%;
  }

  .home__search .input {
    width: 100%;
    box-sizing: border-box;
  }

  .mapboxgl-map {
    overflow: visible;
    min-height: 500px;
  }

}

@media screen and (max-width: 1024px) {
  .home__content {
    flex-direction: column;
  }

  .home__content__maps {
    width: 100%;
    min-height: 500px;
  }

  .home__content__table {
    width: 100%;
    min-height: 500px;
  }

  .home__search .input {
    width: 100%;
    box-sizing: border-box;
  }

  .mapboxgl-map {
    overflow: visible;
    min-height: 500px;
  }
}

.home__content__maps__legend {
  position: absolute;
  z-index: 5;
  bottom: 5px;
  left: 5px;
  background-color: var(--card);
  color: var(--text-color);
  padding: 10px;
  border-radius: 8px;
}

.home__content__maps__satelite {
  position: absolute;
  z-index: 5;
  bottom: 5px;
  right: 5px;
  background-color: var(--card);
  color: var(--text-color);
  padding: 10px;
  border-radius: 8px;
}

.home__content__maps__legend h5 {
  text-align: center;
  margin-bottom: 4px;
}

.home__content__maps__legend ul {
  list-style: none;
}

.home__content__maps__legend ul li {
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.home__content__maps__legend ul li .bullet {
  width: 8px;
  height: 8px;
  border-radius: 100%;
}

.home__content__maps__legend ul li .red,
.yellow,
.green {
  border: 0.5px solid;
}

.home__content__maps__legend ul li .red {
  background-color: red;
}

.home__content__maps__legend ul li .green {
  background-color: green;
}

.home__content__maps__legend ul li .yellow {
  background-color: #ffdf00;
}



.customUnorderList {
  list-style-type: none;
}


.mapboxgl-canvas {
  height: 100% !important;
}

.table-container {
  width: 100%;
  overflow-x: auto;
}

.gridjs {
  min-width: 100%;
  /* Adjust this value based on the desired minimum table width */
}