.star-rating {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  /* min-width: 450px; */
}

.star-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.star-rating button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 2.2rem;
}

.star-rating .on {
  color: #ffd700; /* yellow */
}

.star-rating .off {
  color: #ccc;
}
