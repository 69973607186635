.layouts {
  height: 100vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.nav {
  border-bottom: 1px solid var(--border-color);
  background-color: var(--card);
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 99;
}

.nav__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
}

.nav__left {
  display: flex;
  align-items: center;
}

.nav__logo {
  display: flex;
  align-items: center;
  text-decoration: none;
  gap: 8px;
  color: #3a63d3;
}

.nav__menu {
  list-style: none;
  display: flex;
  align-items: center;
  margin-left: 56px;
}

.nav__menu li a {
  font-weight: 600;
  font-size: 14px;
  color: var(--text-color);
  margin-left: 24px;
  cursor: pointer;
  position: relative;
  text-decoration: none;
}

.nav__menu li a:hover,
.nav__menu li a.active {
  color: #3a63d3;
}

.nav__menu li a::after {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  height: 2px;
  background-color: #3a63d3;
  width: 0;
  bottom: -2px;
  -webkit-transition: width 250ms ease-in-out;
  -moz-transition: width 250ms ease-in-out;
  -o-transition: width 250ms ease-in-out;
  transition: width 250ms ease-in-out;
}

.nav__menu li a.active::after {
  width: 100%;
}

.nav__menu li a:hover::after {
  width: 100%;
  -webkit-transition: width 250ms ease-in-out;
  -moz-transition: width 250ms ease-in-out;
  -o-transition: width 250ms ease-in-out;
  transition: width 250ms ease-in-out;
}

.nav__right {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.nav__right a {
  color: var(--text-color);
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav__right a:hover {
  color: #3a63d3;
}

.nav__right_divider {
  height: 18px;
  width: 1px;
  background-color: var(--text-color);
}

.footer {
  background-color: var(--card);
  border-top: 1px solid var(--border-color);
  padding: 16px 0px;
  display: flex;
  color: var(--text-color);
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
}

.humburger {
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  color: var(--text-color);
}

.humburger {
  position: relative;
}

.humburger__icon {
  position: absolute;
  transition: opacity 0.4s ease;
}

.humburger__icon--hidden {
  opacity: 0;
}

@media screen and (max-width: 768px) {
  .nav__menu {
    position: absolute;
    margin-left: 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    left: 0;
    right: 0;
    top: 58px;
    background-color: var(--card);
    z-index: 11;
    gap: 12px;
    box-shadow: 0px 15px 15px 0px rgba(160, 163, 189, 0.5);
    padding: 24px 20px;
    visibility: hidden;
  }

  .nav__menu_show {
    visibility: visible;
  }

  .nav__menu li a {
    margin: 0;
  }
}

@media screen and (min-width: 768px) {
  .humburger {
    display: none;
  }
}

.humburger {
  position: relative;
}

.humburger__label {
  position: absolute;
  display: block;
  font-size: 8px;
  text-align: center;
  top: 12px; /* Position label below the icon */
  left: 50%; /* Center the label */
  transform: translateX(-50%); /* Necessary to truly center the label */
  opacity: 0; /* Initially hidden */
  transition: opacity 0.4s ease; /* Optional: animate the label */
}

.humburger__icon--hidden + .humburger__label {
  opacity: 1; /* Show label when the hamburger icon is hidden */
}
