.rental-listings {
  margin-top: 24px;
  margin-bottom: 24px;
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  text-align: center;
}

.rental-listings,
.rental-listings p {
  color: var(--text-color);
}

.rental-listings h1 {
  margin-top: -84px;
}

.image-container {
  position: relative;
  height: 500px; /* Set the desired height */
  width: 100%;
  overflow: hidden; /* Hide the portions of the images that are outside the container */

  margin-top: -64px;
}

.image-container img {
  position: absolute;
  height: 100%;
  transform: translate(-50%, -50%);
  object-fit: cover;
}
