:root {
  --dark-body-bg: #091a26;
  --light-body-bg: #fbfffe;
  --white: #fff;
  --black: #0c2233;
  --blue: #3a63d3;
  --light-text-color: #231f20;
  --dark-text-color: #fbfffe;
}

html.light {
  --body-bg: var(--light-body-bg);
  --card: var(--white);
  --text-color: var(--light-text-color);
  --border-color: #f4f4f4;
}

html.light button.gridjs-sort-neutral {
  filter: brightness(0) invert(0);
}

html.dark button.gridjs-sort-neutral {
  filter: brightness(0) invert(1);
  opacity: 1;
}

html.dark {
  --card: var(--black);
  --body-bg: var(--dark-body-bg);
  --text-color: var(--dark-text-color) !important;
  color: #fbfffe !important;
  --border-color: var(--black);
}

*,
::before,
::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--body-bg);
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6,
span,
p,
ul,
li,
a {
  padding: 0;
  margin: 0;
}

.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.flex-1 {
  flex: 1;
}

.container {
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
}

.px-5 {
  padding-left: 20px;
  padding-right: 20px;
}

.btn:disabled {
  background-color: #bcbcbc;
  cursor: not-allowed;
}

.gap-2 {
  gap: 8px;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  color: white;
  font-weight: 700px !important;
  font-size: 14px;
  padding: 10px 16px;
  border: none;
  background-color: #3a63d3;
  cursor: pointer;
  border-radius: 10px;
  -webkit-appearance: none;
  text-decoration: none;
}

.btn-danger {
  background-color: #e33434;
}

.btn:hover {
  transform: translateY(-2px);
}

.btn:active {
  transform: translateY(0);
}

.input {
  display: flex;
  min-width: 300px;
}

.card {
  padding: 24px 20px;
  border-radius: 10px;
  /* height: fit-content; */
  background-color: var(--card);
  box-shadow: 0px 5px 10px rgba(160, 163, 189, 0.1);
}

@media screen and (max-width: 768px) {
  .card {
    padding: 20px;
  }
}

.form-input {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.form-input label {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* color: #b0b0b0; */
}

.form-input label span {
  font-size: 12px;
  font-weight: 400;
}

.form-input:has(input:focus) label,
.form-input:has(textarea:focus) label {
  color: #3a63d3;
}

.form-input input,
.form-input textarea {
  padding: 14px 15px;
  font-weight: 500;
  font-size: 14px;
  color: var(--text-color);
  background: var(--card);
  border: 1px solid #f4f4f4;
  border-radius: 8px;
}

.form-input input:focus,
.form-input textarea:focus {
  border: 1px solid #3a63d3;
  outline: none;
}

.react-mapbox-ac-suggestion:hover {
  background-color: rgba(57, 170, 180, 0.1) !important;
}

.form-input textarea:focus {
  outline: none;
}

.grid {
  display: grid;
  gap: 10px;
}

.grid-col-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-col-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-col-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.col-span-3 {
  grid-column: span 3 / span 3;
}

mapbox-address-autofill {
  display: grid;
}

.text-error {
  font-size: 12px;
  color: #e33434;
  margin-top: -6px;
}

.form-input:has(.text-error) input,
.form-input:has(.text-error) textarea {
  border: 1px solid #e33434;
}

.form-input:has(.text-error):has(input:focus) label,
.form-input:has(.text-error):has(textarea:focus) label {
  color: #e33434;
}

/* p {
  color: #b0b0b0;
} */

hr {
  border: 0.5px solid #b0b0b0;
}

input,
textarea,
button {
  font-family: "system-ui";
}

input[type="date"]:invalid::-webkit-datetime-edit {
  color: #777777;
}

.pagination-container {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-end;
}

.pagination-container button {
  cursor: pointer;
  background: white;
  border: 1px solid #b0b0b0;
  border-radius: 8px;
  padding: 6px 16px;
}

.pagination-container button:hover {
  background: #b0b0b0;
  color: white;
}

.pagination-container button:disabled {
  cursor: not-allowed;
  background: #e0e0e0;
}

.mapboxgl-popup-content {
  padding: 0px 10px;
  max-width: 120px;
  min-width: 90px;
  max-height: 130px;
  min-height: 80px;
  overflow-y: auto;
}

.react-datepicker__input-container input {
  width: 100%;
}

.gridjs-tr {
  cursor: pointer;
}

.gridjs-tr,
.gridjs-tr td {
  height: 44px !important;
}

.gridjs-tr {
  border-bottom: 1px solid #e5e7eb !important;
}

.gridjs-tr:hover td {
  background: rgba(56, 168, 178, 0.1);
}

.gridjs-wrapper {
  height: 390px !important;
}

.gridjs-pagination .gridjs-summary {
  font-size: 14px;
  order: 1;
}

.gridjs-pagination {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2px;
}

.gridjs-container,
th.gridjs-th,
.gridjs-pagination,
.gridjs-pagination .gridjs-pages button {
  color: var(--text-color);
}

td.gridjs-td,
th.gridjs-th,
.gridjs-footer,
.gridjs-pagination .gridjs-pages button,
.gridjs-pagination .gridjs-pages button:disabled,
.gridjs-pagination .gridjs-pages button.gridjs-spread,
.gridjs-pagination .gridjs-pages button.gridjs-currentPage {
  background-color: var(--card);
}

.gridjs-th-fixed {
  min-width: 120px;
  width: 120px;
  top: 0px;
}

.gridjs-container {
  border: 1px solid var(--white);
}

.gridjs-tr:hover {
  background-color: rgba(58, 99, 211, 0.7);
}

.span_bullet {
  width: 8px;
  height: 8px;
  border-radius: 100%;
}

.color-red,
.color-yellow,
.color-green {
  border: 0.5px solid;
}

.color-red {
  background-color: red;
}

.color-green {
  background-color: green;
}

.color-yellow {
  background-color: #ffdf00;
}

.mapboxgl-popup-content {
  min-height: auto !important;
}

.MuiRating-icon {
  /* color: #d0cccc !important; */
  color: rgb(250, 175, 0) !important;
}

/* --------------------Toggle Style in Rental Listing Filter------------------------ */
/* TOGGLE STYLING */
.toggle {
  margin: 0 0 1.5rem;
  box-sizing: border-box;
  font-size: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;
}
.toggle input {
  width: 0;
  height: 0;
  position: absolute;
  left: -9999px;
}
.toggle input + label {
  margin: 0;
  padding: 0.75rem 2rem;
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  border: solid 1px #ddd;
  background-color: #fff;
  font-size: 1rem;
  line-height: 140%;
  font-weight: 600;
  text-align: center;
  box-shadow: 0 0 0 rgba(255, 255, 255, 0);
  transition: border-color 0.15s ease-out, color 0.25s ease-out,
    background-color 0.15s ease-out, box-shadow 0.15s ease-out;
  /* ADD THESE PROPERTIES TO SWITCH FROM AUTO WIDTH TO FULL WIDTH */
  /*flex: 0 0 50%; display: flex; justify-content: center; align-items: center;*/
  /* ----- */
}
.toggle input + label:first-of-type {
  border-radius: 6px 0 0 6px;
  border-right: none;
}
.toggle input + label:last-of-type {
  border-radius: 0 6px 6px 0;
  border-left: none;
}
.toggle input:hover + label {
  border-color: #213140;
}
.toggle input:checked + label {
  background-color: #4b9dea;
  color: #fff;
  box-shadow: 0 0 10px rgba(102, 179, 251, 0.5);
  border-color: #4b9dea;
  z-index: 1;
}
.toggle input:focus + label {
  outline: dotted 1px #ccc;
  outline-offset: 0.45rem;
}
@media (max-width: 800px) {
  .toggle input + label {
    padding: 0.75rem 0.25rem;
    flex: 0 0 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
/* --------------------Toggle Style in Rental Listing Filter------------------------ */

.tw-btn {
  color: #fff !important;
  /* background-color: #5c4cc4 !important; */
  height: fit-content !important;
  min-height: fit-content !important;
}

.bedroom_options {
  background-color: hsl(var(--b1) / var(--tw-bg-opacity));
}

html.light .no_rental_found {
  color: var(--light-text-color);
}

.rental_cards {
  background-color: var(--body-bg) !important;
}

.rental_texts {
  color: var(--text-color) !important;
}

.tw-join {
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  align-items: stretch;
  -webkit-align-items: stretch;
  border-radius: var(--rounded-btn, 0.5rem);
}
