.contact {
  margin-top: 24px;
  margin-bottom: 24px;
  padding-left: 20px;
  padding-right: 20px;
  flex: 1;
  width: 100%;
  max-width: 678px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact__header {
  text-align: center;
  color: var(--text-color);
}

.contact__header p {
  font-size: 20px;
}